export const environment = {
  production: true,
  //  apiUrl: 'https://dhdgu.api.io-market.net/api/v1/gate2bAPI/gate2B',

   apiUrl: {'io-market.net':'https://dhdgu.api.io-market.net/api/v1/gate2bAPI/gate2B',
   'epost.gate2b.net':'https://dhdgu.api.epost.gate2b.net/api/v1/gate2bAPI/gate2B'},

  //apiUrl:'https://api.gate2b.io-procurement.com/api',
  //  apiUrl: 'http://testgate2b.io-procurement.com:5000/api/v1/gate2bAPI/gate2B',
  // GATE2B_URL:'https://gate2b.io-market.net/#/',

  GATE2B_URL:{'io-market.net':'https://gate2b.io-market.net/#/',
  'epost.gate2b.net':'https://edi.epost.gate2b.net/#/'},

  firebase: {
  },
  // API_NODE_END_POINT : 'https://dhdgu.api.io-market.net/api/v1/',

  API_NODE_END_POINT :  {'io-market.net':'https://dhdgu.api.io-market.net/api/v1/',
  'epost.gate2b.net':'https://dhdgu.api.epost.gate2b.net/api/v1/'},

  // API_END_POINT : 'https://fjy29.api.io-market.net/api/v1/',

  API_END_POINT : {'io-market.net':'https://fjy29.api.io-market.net/api/v1/',
  'epost.gate2b.net':'https://fjy29.api.epost.gate2b.net/api/v1/'},

  // LOGIN_URL:"https://accounts.io-market.net/#/auth/login/",
  
  LOGIN_URL: {'io-market.net':'https://accounts.io-market.net/#/auth/login/',
  'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/auth/login/'},

  // ACCOUNTS_API: "https://fjy29.api.io-market.net/api/v1/users",

  ACCOUNTS_API: {'io-market.net':'https://fjy29.api.io-market.net/api/v1/users',
  'epost.gate2b.net':'https://fjy29.api.epost.gate2b.net/api/v1/users'},


  encryptionKey:'RdAO2InTo6jl4ArLYyIInQ==',
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  WebProfile:"https://accounts.io-market.net/assets/webComponent/profile.js",
  WebList:"https://accounts.io-market.net/assets/webComponent/options.js",
  WebProfileLight:"https://accounts.io-market.net/assets/webComponent/profileLight.js",
  WebListLight:"https://accounts.io-market.net/assets/webComponent/optionsLight.js",
  // profile_URL :"https://accounts.io-market.net/#/profile",
  profile_URL:{'io-market.net':'https://accounts.io-market.net/#/profile',
    'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/profile'},
  readmore_URL:"https://www.io-market.com/loesungen/edi-netzwerk/",
  COOKIE_ENABLED_DOMAINS:['api-business.io-market.net','dhdgu.api.io-market.net','fjy29.api.io-market.net','fkbwl.api.io-market.net','fkbwl.api.epost.gate2b.net'],
  cookieDomain:".io-market.net",

  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",
  // LOGOUT_URL :"https://accounts.io-market.net/#/auth/logout/",
  LOGOUT_URL:{'io-market.net':'https://accounts.io-market.net/#/auth/logout/',
  'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/auth/logout/'},


  ticket_URL :"http://api.ticket.g2bngt.io-procurement.com/ws/createTicketFromGate2B.cfc?wsdl&method=createTicketFromGate2B",


  // UPGRADE_PLAN :"https://admin.io-market.net/#/plan",
  UPGRADE_PLAN: {'io-market.net':'https://admin.io-market.net/#/plan',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/plan'},


  BUY_ADDON: {'io-market.net':'https://admin.io-market.net/#/buyaddon/2',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/buyaddon/2'},


  // PDF2XML: "https://admin.io-market.net/#/buyaddon/3",

  PDF2XML: {'io-market.net':'https://admin.io-market.net/#/buyaddon/3',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/buyaddon/3'},

  
  // BILLING_URL: "https://admin.io-market.net/#/billingOverview",


  BILLING_URL: {'io-market.net':'https://admin.io-market.net/#/billingOverview',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/billingOverview'},


  // USER_URL: "https://admin.io-market.net/#/userManagement",
  USER_URL: {'io-market.net':'https://admin.io-market.net/#/userManagement',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/userManagement'},


  // BUSINESS_URL: "https://admin.io-market.net/#/companyDetails",
  BUSINESS_URL: {'io-market.net':'https://admin.io-market.net/#/companyDetails',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/companyDetails'},


  privateKey:{key:"MIIEpAIBAAKCAQEAx5DMt1FGNFvMerMldZeh7o8iPdSrknRV6kCJfTfA60lMsJtFRbgwmED6jdpXoYG+Zh+Lt9nsCm8Mkj2c8ZFa3Gx3ANVZAJqcz7ztR/HFxVw2VIDEbbpQuVfbbf9O1W4WzOgvuWidPNNXuY62WRNnE/H4MkYVE8Bh+dN/c3VQhGycbvmuYjV6cG/EQv21vrJkCn0dMAx/wzo9ZQyxcsU0jUEOJczbnQyr4UoYsWtNQIXpZTQStRR3pbvNeyP2Ab7zo1ridahE+UkETQclaif80fGZ/3DcMpCR1SD0mryUb8z3pAwrygzuORRjs0augbvhAZg2WjVvfWbVepgF3KBlbwIDAQABAoIBAHab6gAeLvKZiMt4RBBC4fxuV7SV/K8bs6B5YP3qMB/nPcxNTHumgENNg1zh42lbOnVp1FmeDLsay0bzlZ7BfFY7pPL62l4Qhpi1OfWjjZ7CMoxAx/5LWQT/YaxbrFgPiT+Z1BuHQMTQ+3eOiXQhowborlH68zX9Bn48JKjTldpNwg2YZvPeZidWGKhqkeQIgj/wsM0fGUl5aR+xqTRt5ZOydjITDXKFQh6Ms+lhW+w73AaKfVZ16VVWrBCXqIjnVM6HUuzSv2SeJmlHZetw9uvvHBDZf0kYzC3DLpTtyqLacoplKc3kxQiV/vJuiwubVgLqHDeEawqEH7jWjKJa1dECgYEA7ptjGuYk1l0H/fWQn0BlPRVZO/l37bbkzyYJZbRh2SAxYgeh+EluXviRXDKwcRSkCnyNSM6cDf33f5U9pw46mCCXZPvfoFaIRJ70SF/2CMtiXyiw+o1ykEBKr12LNiK829+NrAQph9CGaAhm5AmGDsjDN20192Kje2HJ4z5YX7cCgYEA1hzd9HVA9EYCylPzc4BeeZ191ltd053p2a8PVJ2k2cToOcnBz+81MOfPKiyNzfVEdgSS95yCjHRP0CkViVsp/UpVHhVhRYgDU5nskKMfHvu1VG6RPjnWBhzWIwJrm+E6yxl0/NMuLkqQk14tl2NhQqq3kgs/D0TuJks2o6QTOAkCgYBCJWF7+4EKzA+DH6hP/ZITNO/f6HCOvb0AkBLa1v6pKnuGKYeFRRYLzey+Gm8/afFv1sdz/5LTbixGeiD1RsSHl8FghVKhKFvuagJebfvOxFMo/ibRzpSL2MbbhpspDsg8S+UCYsUAmZYpEOs1HE7FmnJD00q6oeWgNqO5E3d8UQKBgQCNx72986rYVkfgvKiYEif9NimuuP+vcoD4sbBgIqeXwIjaQ1yAmXDKeqW20pvwBvqXf73mExBre1WijKIDO4fZftBQRG4OAdvaDzIUMMAZ3ymayECLSPcNQykVM7QYG5O4C5ppFFofMyb7PsDH33n3EF7QaXwPW2CroOCEidBkKQKBgQDnF4bqesdBbtlET7ZnxDtaCJw/kZg1SFdeJWDUCNDOKmMu5zCdoisDFjvt5qLIIPPDPiwxf0hQXGJl76afE9rz03IYC98DtjrKxNmJzH8832PcZgDE4Z//8Bjn4ht+WZN7zcMcpXftrMKVtMLkQCSeBOMaBz4qUj3FcgqOVSKMSA=="},
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  // API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.net/api/v1/',

  API_END_POINT_ADMIN: {'io-market.net':'https://fkbwl.api.io-market.net/api/v1/',
  'epost.gate2b.net':'https://fkbwl.api.epost.gate2b.net/api/v1/'},

  // XSD_API_HOST : 'https://dhdgu.api.io-market.net/api/v1/gate2bAPI/',

  XSD_API_HOST : {'io-market.net':'https://dhdgu.api.io-market.net/api/v1/gate2bAPI/',
  'epost.gate2b.net':'https://dhdgu.api.epost.gate2b.net/api/v1/gate2bAPI/'},


  FORMATS:{'OpenTrans_Special2.1':'.xml','UBL3.0.1':'.XML','UBL3.1':'.XML','ZUGFERD1.0':'.PDF','UBL2.1':'.XML','ABADOC2.0034':'.pdf','EDIFACTD.96A':'.edi','CXMLWithPDF':'.xml', "CXML1.2":'.xml', 'ETS3.1':'.xml', 'SAP02' :'.xml', 'OpenTrans2.1':'.xml','EDIFACTD.01B':'.edi','YellowBill2.0':'.xml','SignedYellowBill2.0':'.xml','Comarch1.0':'.xml','ETS2.0':'.xml','SAP05':'.xml','QR PDF':'.pdf','Meta Coded PDF':'.pdf' },


  // ACCOUNTS_URL:'https://accounts.io-market.net/#/',

  ACCOUNTS_URL:{'io-market.net':'https://accounts.io-market.net/#/',
  'epost.gate2b.net':'https://accounts.epost.gate2b.net/#/'},
  // ADMIN_URL:"https://admin.io-market.net/#/",

  ADMIN_URL:{
  'io-market.net':'https://admin.io-market.net/#/',
  'epost.gate2b.net':'https://admin.epost.gate2b.net/#/'},


  WC_APPS:"https://wc.io-market.net/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.net/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  BEXIO_URL:"https://bexio.io-market.net/",

  // SUPPORT_URL: "https://support.io-market.net/#/edi/",
  SUPPORT_URL:{
    'io-market.net':'https://support.io-market.net/#/edi/',
    'epost.gate2b.net':'https://support.epost.gate2b.net/#/edi/'},
  
  userInfo:"userInfo",
  APPLICATION_VERSION:"8",
  posfinance_id:"41100000007797776",
  interconnect_posfinance_receive_iomID:"41100000000023711",
  MTC_URL: "https://mtc.io-market.net/#/",
  mtc_cookie: "mtc_cookie",
  EBILL_MAIL:"ebillgateway@io-market.com",
  permissions: 'permissions',
  EBILL_QR_INVOICE: "D_EBILL_QR_PDF_SPEZIFIKATION_DE_2022.PDF",
  color:"colorChangeTest",
  EDI_MAIL:"edigateway@io-market.com",
  PDFHEADER_MAIL:"PDFHeader@io-market.com",
  PDFHEADERITEM_MAIL:"PDFHeaderItem@io-market.com",
  EBILL_ERROR_PRICE:"0.20",
  cookieConsent: 'cookieConsent',
  tax:"0.081",
  COMPARE_API: "https://dwncu.api.io-market.net/api/v1/main/",
  // UPGRADE_PACKAGE :"https://admin.io-market.net/#/manageSubscription/package",

  UPGRADE_PACKAGE :{
    'io-market.net':'https://admin.io-market.net/#/manageSubscription/package',
    'epost.gate2b.net':'https://admin.epost.gate2b.net/#/manageSubscription/package'},


  STYLES : {
  'io-market.net':[{'--theme-color-1':'#8B9635'},{'--header-color':'#474d226e'},{'--logo-color':'transparent'}],
  'epost.gate2b.net':[{'--theme-color-1':'#ffcc00'},{'--header-color':'transparent'},{'--logo-color':'transparent'}]},

  
  LOGO_ORIGINAL : {
  'io-market.net':'logo.png',
  'epost.gate2b.net':'e-plus-p-logo.png'},

  LOGO : {"dark":{"io-market.net": "logo.png","epost.gate2b.net": "e_plus_logo_final.png"},
      "light":{ "io-market.net": "iomlogowhite.png","epost.gate2b.net": "e_plus_logo_final.png"}
    },

  FAVI : {
  'io-market.net':[{"icon":'favicon_1.ico',"title":"accounts.io-procurement.com"}],
  'epost.gate2b.net':[{"icon":'ePost Logo Icon.svg',"title":"accounts.epost.gate2b.net"}]},

  FOOTER_TEXT : {
  'io-market.net':'iomarket',
  'epost.gate2b.net':'iomarket'},
  HELP_DESK_CONTACT:  {
    "io-market.net": 'helpdesk@io-market.com',
    "epost.gate2b.net": 'pascal.leutenegger@epostservice.ch',
    "xatena.io-market.net": 'helpdesk@io-market.com'
  },
  SALES_TEAM_CONTACT:{
    "io-market.net": 'sales@io-market.com',
    "epost.gate2b.net": 'cristina.goncalves@epostservice.ch',
    "xatena.io-market.net": 'sales@io-market.com'
  },
  COMPANY_NAME: {
    'io-market.net':'iomarket AG',
    'epost.gate2b.net':'ePost Service AG'
  }

};